import { LitElement, unsafeCSS, CSSResult } from 'lit';
import { property, queryAssignedElements, query } from 'lit/decorators.js';
import { literal, html } from 'lit/static-html.js';
import { C11nPrefix, C11nPrefixLiteral } from 'virtual:c11n-prefix';
import { appendStylesheet, c11nElement } from '../core';
import styles from './switch.scss?inline';
import slotStyles from './switch.slot.scss?inline';
import { C11nIconLiteral } from '../icon';

export type SwitchLabelPosition = 'trailing' | 'leading';

@c11nElement()
export class SwitchComponent extends LitElement {
  static selector = `${C11nPrefix}-switch`;
  static literal = literal`${C11nPrefixLiteral}-switch`;
  static override styles: CSSResult = unsafeCSS(styles);

  // Some attributes (checked) were being stripped in Stencil apps,
  // but we found a simple workaround. If using @property
  // we may need to differentiate the name from the attribute
  // https://github.com/ionic-team/stencil/issues/2703
  @property({ attribute: 'c11n-web-switch', type: Boolean, reflect: true })
  readonly _c11n_web_switch: boolean = true;

  @property({ attribute: 'label-position', type: String, converter: (value: string | null) => value ?? 'trailing' })
  labelPosition?: SwitchLabelPosition = 'trailing';

  @property({ attribute: 'label-hidden', type: Boolean, reflect: true })
  labelHidden: boolean = false;

  @property({ attribute: 'checked', type: Boolean, reflect: true })
  _checked: boolean = false;

  @property({ attribute: 'hide-icon', type: Boolean, reflect: true })
  hideIcon: boolean = false;

  @queryAssignedElements({ selector: 'label' })
  labelEls!: Array<HTMLLabelElement>;

  @queryAssignedElements({ selector: 'button' })
  buttonEls!: Array<HTMLButtonElement>;

  @queryAssignedElements({ selector: 'input' })
  inputEls!: Array<HTMLInputElement>;

  get labelEl(): HTMLLabelElement {
    return this.labelEls && this.labelEls[0];
  }

  get buttonEl(): HTMLButtonElement {
    return this.buttonEls && this.buttonEls[0];
  }

  get inputEl(): HTMLInputElement {
    return this.inputEls.length 
      ? this.inputEls[0]
      : this.querySelector('input[type="checkbox"]') as HTMLInputElement;
  }

  get switchTriggerEl(): HTMLElement {
    return this.buttonEl ?? this.inputEl; 
  }

  override connectedCallback(): void {
    super.connectedCallback();
    this.appendStyles();

    setTimeout(() => {
      this.updateSlotAttributes();

      if (this.inputEl) {
        this.inputEl.addEventListener('keydown', this.handleKeyDown.bind(this));
      }
    });
  }
  
  override disconnectedCallback(): void {
    super.disconnectedCallback();

    if (this.inputEl) {
      this.inputEl.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      !this._checked ? this.inputEl.setAttribute('checked', '') : this.inputEl.removeAttribute('checked');
      this.toggleSwitch();
    }
  }

  updateSlotAttributes() {
    if (this.buttonEl) {
      this.buttonEl.setAttribute('role', 'switch');
      this.buttonEl.setAttribute('aria-checked', this._checked ? 'true' : 'false');
    } else {
      this._checked = this.inputEl.checked ? true : false;
    }

    this.setAriaLabel(this._checked);
    this.setAriaHidden();
  }

  toggleSwitch() {
    this._checked = !this._checked;

    if (this.buttonEl) {
      this.buttonEl.setAttribute('aria-checked', this._checked ? 'true' : 'false');
    }

    this.setAriaLabel(this._checked);
  }

  setAriaLabel(checked: boolean) {
    const dataAttribute = checked ? '[data-on]' : '[data-off]';
    const dataEl = this.querySelector(dataAttribute) as HTMLElement;
    const dataLabel = dataEl?.ariaLabel || dataEl?.textContent || (checked ? 'on' : 'off');

    this.switchTriggerEl.setAttribute('aria-label', dataLabel);
  }

  setAriaHidden() {
    (<HTMLElement>this.querySelector('[data-on]'))?.setAttribute('aria-hidden', 'true');
    (<HTMLElement>this.querySelector('[data-off]'))?.setAttribute('aria-hidden', 'true');
  }

  appendStyles() {
    const slotStylesheet: string = slotStyles.toString()
      .replaceAll(/\bc11n-web-switch\b/g, this.tagName.toLowerCase());
    appendStylesheet({ 
      target: this as Node, 
      styles: slotStylesheet, 
      name: `${this.tagName.toLowerCase()}-slot`
    });
  }

  override render() {
    return html`
      ${this.hideIcon ? html`` : html`<${C11nIconLiteral} name="check"></${C11nIconLiteral}>`}
      <slot @click="${this.toggleSwitch}"></slot>
    `;
  }
}

export const C11nSwitch = SwitchComponent.selector;
export const C11nSwitchLiteral = SwitchComponent.literal;